import type { ComputedRef } from 'vue'
import { useField as _useFieldValidation } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import type { ZodSchema, ZodString, ZodType } from 'zod'
import { z } from 'zod'
import {
  FieldRequiredLevel,
  FieldValidationType,
  type INodeField,
} from '@manager'

const getZodSchema = (
  validationType: FieldValidationType | null | undefined,
  name: string,
): ZodType => {
  const errorMap = (issue, ctx) => {
    if (
      issue.code === z.ZodIssueCode.invalid_type &&
      issue.received === 'null'
    ) {
      return { message: `${name} is required` }
    }
    return { message: ctx.defaultError }
  }

  /// Define the type mapping
  const typeMapping: Record<FieldValidationType, ZodSchema> = {
    [FieldValidationType.STRING]: z.string({ errorMap }),
    [FieldValidationType.INTEGER]: z.number({ errorMap }).int(),
    [FieldValidationType.DECIMAL]: z.number({ errorMap }),
    [FieldValidationType.CURRENCY]: z.number({ errorMap }),
    [FieldValidationType.PERCENTAGE]: z.number({ errorMap }).min(0).max(100),
    [FieldValidationType.DATE]: z.date({ errorMap }),
    [FieldValidationType.BOOLEAN]: z.boolean({ errorMap }),
    [FieldValidationType.EMAIL]: z.string({ errorMap }).email(),
    [FieldValidationType.PHONE_NUMBER]: z.string({ errorMap }), // Example for international phone number format `.regex(/^\+?[1-9]\d{1,14}$/)`
    [FieldValidationType.ADDRESS]: z.string({ errorMap }), // Customize if you have specific address format requirements
    [FieldValidationType.REGULAR_EXPRESSION]: z.string({ errorMap }),
  }

  if (!validationType || !typeMapping[validationType]) {
    // throw new Error(`Unsupported value type: ${validationType}`)
    console.error(new Error(`Unsupported value type: ${validationType}`))
    return z.string()
  }

  return typeMapping[validationType]
}

function setValidationRule(
  validationRule: string | null | undefined,
  fieldSchema: ZodType,
) {
  const regex = validationRule ? stringToRegex(validationRule) : null
  if (!regex) {
    // throw new Error(`Invalid regular expression: ${validationRule}`)
    console.error(new Error(`Invalid regular expression: ${validationRule}`))
    return fieldSchema
  }
  return (fieldSchema as ZodString).regex(regex)
}

function setRequiredLevel(
  requiredLevel: FieldRequiredLevel | undefined,
  fieldSchema: ZodType,
): ZodType {
  switch (requiredLevel) {
    case FieldRequiredLevel.NOT_REQUIRED:
    case FieldRequiredLevel.RECOMMENDED:
      return fieldSchema.nullable()
    case FieldRequiredLevel.REQUIRED:
      // No changes needed as it's required by default
      return fieldSchema
    default: {
      // throw new Error(`Unsupported required level: ${requiredLevel}`)
      console.error(new Error(`Unsupported required level: ${requiredLevel}`))
      return fieldSchema
    }
  }
}

export const useFieldValidation = (
  node: INodeField,
  path: ComputedRef<string>,
) => {
  const { label, validationType, validationRule, requiredLevel } = node

  // Get the Zod schema based on the validation type
  let fieldSchema: ZodType = getZodSchema(validationType, label)

  // Set the validation rule if it's a regular expression
  if (validationType === FieldValidationType.REGULAR_EXPRESSION) {
    fieldSchema = setValidationRule(validationRule, fieldSchema)
  }

  // Set the required level
  fieldSchema = setRequiredLevel(requiredLevel, fieldSchema)

  return _useFieldValidation(path, toTypedSchema(fieldSchema))
}
