import type { Ref } from 'vue'
import type { IManagerFormNodeValue, INodeField } from '@manager'

export const useFieldCalculation = (
  node: INodeField,
  parentValue: Ref<IManagerFormNodeValue>,
) => {
  const calculate = (value: unknown) => {
    // TODO: Implement calculation logic
    if (
      node.valueType === 'PERCENTAGE' &&
      value !== undefined &&
      value !== null
    ) {
      return Number(value) / 100
    }

    if (
      node.valueType === 'CURRENCY' &&
      value !== undefined &&
      value !== null
    ) {
      return Number(value)
    }

    return value ?? undefined
  }

  const registerCalculated = () => {
    if (`calculated_${node.name}` in parentValue.value) return
    Object.defineProperty(parentValue.value, `calculated_${node.name}`, {
      get() {
        return calculate(this[node.name])
      },
      enumerable: false,
    })
  }

  return { calculate, registerCalculated }
}
