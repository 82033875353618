import type { IManagerFormNodeValue, INodeField } from '@manager'
import type { Ref } from 'vue'

export const useFieldCache = (
  node: INodeField,
  parentValue: Ref<IManagerFormNodeValue>,
) => {
  const __stored = ref<any>(null)

  tryOnActivated(() => {
    if (!parentValue.value[node.name] && __stored.value) {
      parentValue.value[node.name] = __stored.value
    }
  })
  tryOnDeactivated(() => {
    __stored.value = parentValue.value[node.name]

    // Only set value to null if the key exists in the parentValue
    if (node.name in parentValue.value) {
      parentValue.value[node.name] = null
    }
  })
}
