<script setup lang="ts">
import {
  FieldRequiredLevel,
  FieldUsage,
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'
import type {
  IManagerFieldEmits,
  IManagerFieldProps,
  IManagerFieldSlots,
} from './types'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerFieldEmits>()

defineSlots<IManagerFieldSlots>()
const slots = useSlots()

const {
  modelValue,
  parentValue,
  id,
  type,
  colSize,
  label,
  path,
  validation: { errorMessage },
  help,
  handleChange,
  handleBlur,
} = useField(props, emit)

let castPlugin
if (!slots.default) {
  const { castPlugin: _castPlugin } = useFieldCast(props.node)
  castPlugin = _castPlugin

  // Initialize calculated value, after setting default value
  const { registerCalculated } = useFieldCalculation(props.node, parentValue)
  registerCalculated()

  useFieldCache(props.node, parentValue)
}
</script>

<template>
  <!-- Field -->
  <div
    v-if="node.usage !== FieldUsage.HIDDEN"
    :class="colSize"
    :data-path="path"
    :data-invalid="!!errorMessage"
  >
    <slot
      v-bind="{
        id,
        name: node.name,
        label,
        type,
      }"
    >
      <Tooltip
        class="bg-gray-950 text-xs"
        outer-class="grow flex"
        :content="node.tooltip ? node.tooltip : undefined"
        placement="top"
      >
        <FormKit
          :id="id"
          v-model="modelValue"
          :type="type"
          :name="node.name"
          :label="label"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          :plugins="[castPlugin]"
          outer-class="w-full "
          :min="node.min ?? undefined"
          :max="node.max ?? undefined"
          @input="handleChange"
          @blur="handleBlur"
        >
          <template v-if="node.prefix" #prefix>
            <span
              class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              {{ node.prefix }}
            </span>
          </template>
          <template v-if="node.suffix" #suffix>
            <span
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              {{ node.suffix }}
            </span>
          </template>
        </FormKit>
      </Tooltip>
    </slot>
    <slot v-if="help" name="help" :text="help">
      <div class="mt-1 text-sm">{{ help }}</div>
    </slot>
    <slot v-if="errorMessage" name="errors" :message="errorMessage">
      <div class="text-red mt-1 text-sm">
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>
