import {
  FieldRequiredLevel,
  joinPath,
  parseFieldType,
  parseSizeToGrid,
  resolveNodeId,
  useFieldCast,
  useFieldValidation,
} from '@manager'
import type { IManagerFieldProps } from '@manager/components/Field/types'

export const useField = <Name extends string>(
  props: IManagerFieldProps,
  emit: (name: Name, ...args: any[]) => void,
) => {
  // Props
  const path = computed(() => joinPath(props.parentPath!, props.node.name))
  const id = computed(() =>
    joinPath(props.parentPath!, resolveNodeId(props.node)),
  )
  const type = parseFieldType(props.node)
  const colSize = computed(() => parseSizeToGrid(props.node.size))
  const label = computed(() => {
    let label = props.node.label

    if (props.node.requiredLevel === FieldRequiredLevel.REQUIRED) {
      label += ' *'
    }

    return label
  })

  const { cast } = useFieldCast(props.node)

  // Validation
  const validation = useFieldValidation(props.node, path)

  // Model value
  const parentValue = useVModel(
    props as Required<IManagerFieldProps>,
    'parentValue',
    emit,
  )
  const modelValue = computed({
    get: () => parentValue.value[props.node.name],
    set: (value) => {
      parentValue.value[props.node.name] = value
    },
  })

  // Cast value and fallback to default
  modelValue.value = cast(modelValue.value, props.node.defaultValue)
  validation.handleChange(parentValue.value[props.node.name], false)

  const help = computed(() => {
    // if (props.node.description) return props.node.description
    if (props.node.requiredLevel === FieldRequiredLevel.RECOMMENDED)
      return 'This field is recommended'
  })

  // Submit
  const { onSubmit: _submit } = useManagerNodeSubmit(props.node.usage)

  const handleChange = (v: unknown, shouldValidate = true) => {
    validation.handleChange(v, shouldValidate)
    _submit()
  }

  const handleBlur = (e?: Event, shouldValidate = true) => {
    validation.handleBlur(e, shouldValidate)
  }

  return {
    id,
    type,
    colSize,
    label,
    path,
    modelValue,
    parentValue,
    validation,
    help,
    handleChange,
    handleBlur,
  }
}
